import React, { useState, useEffect } from "react";
import Button from "components/CustomButtons/Button.js";
import ExchangeOutlinedInput from "components/ExchangeOutlinedInput/ExchangeOutlinedInput";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { inputLimitPass, inputFinalVal } from "features/helpers/utils";
import BigNumber from "bignumber.js";
import { tokens, pools } from "features/configure";
import { useParams } from "react-router-dom";
import { convertAmountToNumber } from "features/helpers/bignumber";
import { convertAmountFromRawNumber } from "features/helpers/bignumber";
import _ from "lodash";
import {
  useFetchStakeDetail,
  useFetchApproval,
  useFetchStake,
} from "features/stake/redux/hooks";

import {
  useFetchPoolDetails,
} from "../home/redux/hooks";

import { useFetchPrice } from "../price/redux/hooks";

import Tabs from "components/CustomTabs/Tabs.js";
import { useConnectWallet } from "features/home/redux/hooks";
import { Grid, Slider } from "@material-ui/core";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "30%",
    minWidth: 360,
    backgroundColor: theme.palette.background.paper,
    padding: "15px 25px",
  },
}));

const PrettoSlider = withStyles({
  root: {
    color: "white",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

export default function Stake() {

  const REWARD_PER_SECOND = convertAmountFromRawNumber(3094569830246914000);
  const SECONDS_PER_YEAR = 86400 * 30 * 12;
  const poolIdForLp = 1;

  const { poolName } = useParams();

  const data = _.find(pools, { stakedTokenName: poolName.toUpperCase() });
  const [depositBalance, setDepositBalance] = useState("");
  const { fetchStakeDetail, fetchStakeDetailPending, detail } =
    useFetchStakeDetail();

  const { fetchStake, fetchStakePending } = useFetchStake(data);

  const { fetchPrice, priceData, lpData } = useFetchPrice();
  const { poolDetails, fetchPoolDetails } = useFetchPoolDetails();

  const { web3, address } = useConnectWallet();
  const classes = useStyles();

  const { fetchApproval, fetchApprovalPending } = useFetchApproval();
  const [lockedValue, setLockedValue] = useState(52);
  const [stakeAble, setStakeAble] = useState(false);

  const [poolTotalStaked, setPoolTotalStaked] = useState([]);
  const [poolTotalSupply, setPoolTotalSupply] = useState("");
  const [poolTotalValueLocked, setPoolTotalLocked] = useState([]);
  const [poolApr, setPoolApr] = useState([]);

  const [tabValue, setTabValue] = React.useState(1);

  const handleTabChange = (event, newValue) => {
    if (newValue == 0) {
      // flexable
      setLockedValue(0);
    } else if (newValue == 1) {
      // locked
      setLockedValue(52);
    }

    setTabValue(newValue);
  };
  const handleSliderChange = (event, newValue) => {
    setLockedValue(newValue);
  };

  useEffect(() => {
    if (web3 && address) {
      fetchStakeDetail({ web3, address, data });
      fetchPrice({ web3 });
      fetchPoolDetails();

      const id = setInterval(() => {
        fetchStakeDetail({ web3, address, data });
        fetchPrice({ web3 });
        fetchPoolDetails();

      }, 10000);
      return () => clearInterval(id);
    }
  }, [web3, address]);

  useEffect(() => {
    let poolTotalStaked = [];
    let poolTotalSupply = [];
    let poolLength = poolDetails ? poolDetails.length : 0;
    for (let i = 0; i < poolLength; i++) {
      poolTotalStaked.push(poolDetails[i].totalStaked);
      poolTotalSupply.push(poolDetails[i].totalSupply);
    }
    setPoolTotalStaked(poolTotalStaked);
    setPoolTotalSupply(poolTotalSupply);
  }, [poolDetails, priceData]);

  useEffect(() => {
    const walletBalance = detail.balance ? detail.balance : 0;
    const hasZeroBalance = walletBalance == 0;
    setStakeAble(!Boolean(fetchStakePending || hasZeroBalance));
  }, [fetchStakePending, detail]);

  useEffect(() => {
    let poolTotalValueLocked = [];
    let poolApr = [];

    let poolTotalStakedLength = poolTotalStaked ? poolTotalStaked.length : 0;
    for (let i = 0; i < poolTotalStakedLength; i++) {
      if (i === poolIdForLp) {
        let lpTotalSupply = lpData ? lpData.totalSupply : 0;
        let lpUsdcReserve = lpData ? lpData.usdcReserve : 0;
        let lpPrice = (lpUsdcReserve * 2) / lpTotalSupply;
        let apr =
          poolTotalStaked[i] * lpPrice < 10
            ? 0
            : (
                REWARD_PER_SECOND *
                SECONDS_PER_YEAR *
                pools[i].weight *
                priceData *
                100) /
              (poolTotalSupply[i] * lpPrice);
        poolTotalValueLocked.push(
          parseFloat(poolTotalStaked[i] * lpPrice).toFixed(2)
        );
        poolApr.push(apr);
      } else {
        poolTotalValueLocked.push(
          parseFloat(poolTotalStaked[i] * priceData).toFixed(2)
        );
        let apr =
          poolTotalStaked[i] * priceData < 10
            ? 0
            : (REWARD_PER_SECOND * SECONDS_PER_YEAR * pools[i].weight * 100) /
              poolTotalSupply[i];
        poolApr.push(apr);
      }

      let avgLockedDay = parseFloat(
        (52 * 7 * (poolTotalSupply[i] - poolTotalStaked[i])) /
          poolTotalStaked[i]
      ).toFixed(2);


    }
    setPoolTotalLocked(poolTotalValueLocked);

    setPoolApr(poolApr);
  }, [poolTotalStaked, poolTotalSupply, priceData, lpData]);

  const setDepositMaximumInput = () => {
    const total = detail.balance;
    if (total > 0) {
      const amount = new BigNumber(total).toFormat();
      setDepositBalance(amount.replaceAll(",", ""));
    }
  };
  const changeDepositInputValue = (event) => {
    let value = event.target.value;
    const total = detail.balance;
    if (!inputLimitPass(value)) {
      return;
    }

    setDepositBalance(inputFinalVal(value, total));
  };

  const onApproval = () => {
    fetchApproval({
      tokenAddress: data.tokenAddress,
      contractAddress: data.poolAddress,
    });
  };

  const onDeposit = () => {
    let amountValue = depositBalance
      ? depositBalance.replaceAll(",", "")
      : depositBalance;

    fetchStake({
      address,
      web3,
      poolData: data,
      duration: lockedValue,
      amount: convertAmountToNumber(amountValue),
    }).then(()=>{setDepositBalance("");});
  };

  function valuetext(value) {
    return `${value} weeks`;
  }
  const renderInputField = (isLocked) => {
    let boostweight = isLocked?(1 + lockedValue / 52) : 1;
    return (
      <div>
        {isLocked && (
          <div>
            <Grid container>
              <Grid item xs={6}>
                Locked for: {lockedValue} weeks
              </Grid>
              <Grid item xs={6}>
                weight: {(1 + lockedValue / 52).toFixed(2)}
              </Grid>
            </Grid>
            <PrettoSlider
              min={0}
              max={52}
              style={{ height: 20 }}
              defaultValue={52}
              value={typeof lockedValue === "number" ? lockedValue : 0}
              onChange={handleSliderChange}
              step={1}
            />
          </div>
        )}
        <CustomOutlinedInput
          value={depositBalance}
          onClick={() => {}}
          availabletext={`Wallet: ${_.get(detail, "balance", "-")} ${poolName}`}
          onChange={changeDepositInputValue}
          setMax={() => setDepositMaximumInput()}
        />
        <div style={{float:'left', marginTop:15}}>Est APR : {`${poolName.toUpperCase() == 'XTOKEN' ? (boostweight*(poolApr[0])).toFixed(0):(boostweight*poolApr[1]).toFixed(0)}%`}</div>

        <div style={{ marginTop: 100 }}>
          <div className="alertCard">
            Be aware that there are always risks associated with staking
            contracts. You assume all responsibility.
          </div>
          {detail && parseFloat(detail.allowance) > 0 ? (
            <Button
              disabled={!stakeAble}
              onClick={() => {
                onDeposit();
              }}
              color="secondary"
            >
              Stake
            </Button>
          ) : (
            <Button
              disabled={fetchApprovalPending}
              onClick={() => {
                onApproval();
              }}
              color="secondary"
            >
              Approve
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div style={{ padding: "100px 0", minHeight: "100vh" }}>
      <div className="card" style={{ maxWidth: 800, margin: "0 auto" }}>
        <div className="header">Stake {data.stakedTokenName}</div>
        <div className="subHeader"></div>
        <div className="card-black" style={{ maxWidth: 650, margin: "0 auto" }}>
          <Tabs
            tabIndex={tabValue}
            handleChange={handleTabChange}
            tabs={[
              {
                label: "Flexible",
                content: renderInputField(false),
              },
              {
                label: "Locked",
                content: renderInputField(true),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}
