export const pools = [
  {
    pid: 0,
    stakedTokenName: "XTOKEN",
    stakedTokenSymbol: "X",
    rewardTokenName:"XTOKEN",
    stakedTokenDecimals: 18,
    tokenAddress: "0x7f3141c4d6b047fb930991b450f1ed996a51cb26",
    poolAddress: "0x8bEC221B4c4cf3481A6644E635Cf410aa9a2CbB6",
    getUrl:"https://app.sushi.com/swap?outputCurrency=0x7f3141c4d6b047fb930991b450f1ed996a51cb26",
    weight: 1,
    toFixed: 2
  }
];
