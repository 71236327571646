import axios from "axios";
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_PRICE_BEGIN,
  FETCH_PRICE_SUCCESS,
  FETCH_PRICE_FAILURE,
} from './constants';
import { uniswapV2PairABI, liquidityBootstrappingPoolABI, balancerVaultABI, contracts } from 'features/configure';
import { convertAmountFromRawNumber } from "../helpers/bignumber";
import _ from "lodash";

export function fetchPrice({ web3 }) {
  return dispatch => {
    dispatch({
      type: FETCH_PRICE_BEGIN
    });

    const promise = new Promise((resolve, reject) => {

    // const copperLaunchPoolContract = new web3.eth.Contract(liquidityBootstrappingPoolABI, contracts.copperLaunchPoolContract.address);
    // const balancerVaulContract = new web3.eth.Contract(balancerVaultABI, contracts.balancerVault.address);
    // const uniswapPairContract = new web3.eth.Contract(uniswapV2PairABI, contracts.uniswapLpPairAddress.address);

      Promise.all([
        axios.get('https://api.coingecko.com/api/v3/simple/price', {
          params: {  ids: 'x-2', vs_currencies: 'usd' },
        }),
        axios.get('https://api.coingecko.com/api/v3/simple/price', {
          params: { ids: 'ethereum', vs_currencies: 'usd' },
        }),
        axios.get(`https://analytics.vault.inc/api/stats/priceHistory?name=x`)
        // uniswapPairContract.methods.getReserves().call(),
        // uniswapPairContract.methods.totalSupply().call()
      ]).then(data => {
        dispatch({
          type: FETCH_PRICE_SUCCESS,
          data
        });
        resolve(data);
      })
        .catch(error => {
          dispatch({
            type: FETCH_PRICE_FAILURE
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchPrice() {
  const dispatch = useDispatch();

  const { fetchPricePending, priceData, lpData, chart } = useSelector(state => ({
    fetchPricePending: state.price.fetchPricePending,
    priceData: state.price.priceData,
    lpData: state.price.lpData,
    chart: state.price.chart,
  }));

  const boundAction = useCallback(
    data => {
      return dispatch(fetchPrice(data));
    },
    [dispatch]
  );

  return {
    fetchPrice: boundAction,
    fetchPricePending,
    priceData,
    lpData,
    chart
  };
}

export function reducer(state = { fetchPricePending: false, priceData: {}, lpData: {}, chart:[] }, action) {
  switch (action.type) {
    case FETCH_PRICE_BEGIN:
      return {
        ...state,
        fetchPricePending: {
          ...state.fetchPricePending
        },
      };

    case FETCH_PRICE_SUCCESS:
      // const tokenWeight = convertAmountFromRawNumber(action.data[0][0]);
      // const wethWeight = convertAmountFromRawNumber(action.data[0][1]);
      // const tokenBalances = convertAmountFromRawNumber(action.data[1].balances[0]);
      // const wethBalances = convertAmountFromRawNumber(action.data[1].balances[1], 18);
      const ethPrice = action.data[1].data['ethereum'].usd;
      const priceData = action.data[0].data['x-2'].usd;
      let chart = action.data[2].data

      let latest = _.last(chart);
      
      let modifiedLatest = (_.dropRight(latest));
      modifiedLatest.push(priceData);
      
      
      let modifiedChart = (_.dropRight(chart));
      modifiedChart.push(modifiedLatest);
      // const priceData = action.data[0].data['blockchainspace'].usd;
      // const lpData = {
      //   guildReserve: convertAmountFromRawNumber(action.data[1]._reserve0),
      //   usdcReserve: convertAmountFromRawNumber(action.data[1]._reserve1, 6),
      //   totalSupply: convertAmountFromRawNumber(action.data[2])
      // }
      return {
        ...state,
        priceData: priceData,
        chart: modifiedChart,
        // lpData: lpData,
        fetchPricePending: {
          ...state.fetchPricePending
        },
      };

    case FETCH_PRICE_FAILURE:
      return {
        ...state,
        fetchPricePending: {
          ...state.fetchPricePending
        },
      };

    default:
      return state;
  }
}
