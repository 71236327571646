export const contracts = {
  multicall: {
    address: "0x29de9dfa4adfe50c878fb2ffff8c1b28d9e24355",
  },
  liquidityMiningManager: {
    address: "0xCF86AD2477c72E0490765BA8f04B97A03FDd7b9d",
  },
  escrowedReward: {
    address: "0x22bB102b84735564DA3a394bAFcAFeE049467dC8",
  },
  view: {
    address: "0xDB8D28f10b2d7A5232AA84D1a626b01640F06648",
  },
  singleTokenPool: {
    address: "0x8bEC221B4c4cf3481A6644E635Cf410aa9a2CbB6",
  },
  lpPool: {
    address: "",
  },
  uniswapLpPairAddress : {
    address: ""
  },
  copperLaunchPoolContract:{
    address: "0xeEDcA0C2cBa983b718C66094fC8E41F9eD52F82a"
  },
  balancerVault: {
    address: "0xba12222222228d8ba445958a75a0704d566bf2c8",
    poolId: "0xeedca0c2cba983b718c66094fc8e41f9ed52f82a00020000000000000000011c"
  }
};
